// WRAPPER ----------------------------------------------------------------------------------------

/**
 * Wrapper de l'API Fetch
 * @param {string} url
 * @param {object} options
 * @returns {Promise<any>}
 */
async function useApi(url, options = {}) {
  const authStore = useAuthStore();
  const httpStore = useHttpStore();
  const errors = useFormError();

  const { PENDING } = useAuthStatus();

  // Access token pending ---------------------------------------------------------------------------------
  if ((await useTokenStatus()) === PENDING) {
    // Add api to pending queue  ------------------------------------------------------------------
    return new Promise((resolve, reject) => {
      httpStore.addApiQueue({
        promise: () => useApi(url, options),
        resolve,
        reject,
      });
    });
  }

  // Build url ------------------------------------------------------------------------------------
  const input = new URL(url, options.baseUrl ?? import.meta.env.VITE_API_URL);

  const headers = options.headers || {};
  if (authStore.apiToken.access) {
    headers.Authorization = `Bearer ${authStore.apiToken.access}`;
  }

  // Build request init -------------------------------------------------------------------------
  const init = {
    method: options.method,
    headers,
    signal: options.signal ?? null,
  };

  // add query  --------------------------------------------------------------------------
  if (options.query) {
    Object.keys(options.query).forEach((key) => {
      let data = unref(options.query[key]);
      if (Array.isArray(data)) {
        data = JSON.stringify(data);
      }
      input.searchParams.append(key, data);
    });
  }

  // add body  --------------------------------------------------------------------------
  if (options.json) {
    init.headers["Content-Type"] = "application/json";
    init.body = JSON.stringify(options.json);
  } else if (options.form) {
    init.body = new FormData();
    Object.keys(options.form).forEach((key) =>
      init.body.append(key, unref(options.form[key])),
    );
  }

  return fetch(input, init)
    .then((res) => useHandleResponse(res))
    .catch(async (e) => {
      if (e instanceof HttpException) {
        switch (e.data.status) {
          case 401:
            if (e.data.error === "default") {
              authStore.resetAccess();
              if (
                authStore.refreshToken.access &&
                authStore.refreshToken.exp > Date.now()
              ) {
                return useApi(url, options);
              }
            }
            authStore.resetAuth();
            useSnackbar.error({
              key: useSnackbar.LOGOUT,
              message: errors.getWording("http", 401, "logout"),
              close: true,
            });
            break;
          case 403:
            if (e.data.error === "user_state") {
              await authStore.logout();
              useSnackbar.error({
                key: useSnackbar.LOGOUT,
                message: errors.getWording("http", 403, "user_state"),
                close: true,
              });
            }
            break;
          case 500:
            useSnackbar.error({
              key: useSnackbar.ERROR,
              message: errors.getWording("http", 500, e.code),
              close: true,
            });
            break;
          default:
            break;
        }
        throw e;
      }

      const error =
        import.meta.client && !navigator.onLine ? "offline" : "fetch";

      useSnackbar.error({
        key: useSnackbar.ERROR,
        message: errors.getWording("http", 500, error),
        close: true,
      });
      throw new HttpException({
        status: 500,
        data: {
          status: 500,
          error,
        },
      });
    });
}

// HELPERS ----------------------------------------------------------------------------------------

function httpGet(url, data = null, options = {}) {
  options.method = "GET";
  options.query = data;
  return useApi(url, options);
}

function httpDelete(url, data = null, options = {}) {
  options.method = "DELETE";
  options.query = data;
  return useApi(url, options);
}

function httpPost(url, data = null, options = {}) {
  options.method = "POST";
  options.json = data;
  return useApi(url, options);
}

function httpPut(url, data = null, options = {}) {
  options.method = "PUT";
  options.json = data;
  return useApi(url, options);
}

function httpPatch(url, data = null, options = {}) {
  options.method = "PATCH";
  options.json = data;
  return useApi(url, options);
}

function httpForm(url, data = null, options = {}) {
  options.method = "POST";
  options.form = data;
  return useApi(url, options);
}

export const useHttp = {
  get: (url, data = null, options = {}) => httpGet(url, data, options),
  delete: (url, data = null, options = {}) => httpDelete(url, data, options),
  post: (url, data = null, options = {}) => httpPost(url, data, options),
  put: (url, data = null, options = {}) => httpPut(url, data, options),
  patch: (url, data = null, options = {}) => httpPatch(url, data, options),
  form: (url, data = null, options = {}) => httpForm(url, data, options),
};

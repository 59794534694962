let storePrefixe = "AGENDA_ORG";

switch (import.meta.env.VITE_MODE) {
  case "developpement":
    storePrefixe = `LOCAL_${storePrefixe}`;
    break;
  case "testing":
    storePrefixe = `DEV_${storePrefixe}`;
    break;
  default:
    break;
}

let hasLocalStorage = null;
const fakeStorage = {};

/**
 * Check is local storage is supported
 */
function isSupported() {
  if (import.meta.server || hasLocalStorage !== null) return hasLocalStorage;
  try {
    const key = `SUPPORT_${storePrefixe}`;
    localStorage.setItem(key, key);
    localStorage.removeItem(key);
    hasLocalStorage = true;
  } catch {
    hasLocalStorage = false;
    useBugsnag().notify("LocalStorage not supported");
  }
  return hasLocalStorage;
}

function get(field) {
  if (isSupported()) {
    const item = localStorage.getItem(`${storePrefixe}-${field}`) ?? null;
    if (item) {
      return JSON.parse(item);
    }
  }
  return fakeStorage[field] ?? null;
}

function set(field, data) {
  if (isSupported()) {
    localStorage.setItem(`${storePrefixe}-${field}`, JSON.stringify(data));
  } else {
    fakeStorage[field] = data;
  }
}

function remove(field) {
  if (isSupported()) {
    localStorage.removeItem(`${storePrefixe}-${field}`);
  } else {
    delete fakeStorage[field];
  }
}

/**
 * Local storage helper, fallback to a object if not supported
 */
export const useLocaleStorage = {
  get,
  set,
  remove,
};

/**
 * Check if a route can be access, return a appropriate route otherwise
 * If a authentification is necessary, a redirect url is stored in the session storage
 * @param {*} to
 * @returns {Object|null}
 */
export function useRouteValidation(to) {
  const { ANONYMOUS, CONNECTED, CONTROLLED } = useAuthStatus();

  const authStore = useAuthStore();
  const router = useRouter();
  let route = null;
  let redirect = null;

  // Not in control state
  if (!authStore.isConnected || authStore.isControlled) {
    switch (to.meta.auth) {
      case ANONYMOUS:
        if (authStore.isConnected) {
          route = { name: "user" };
        }
        break;
      case CONNECTED:
        if (authStore.isControlled) {
          route = { name: "user" };
        } else if (!authStore.isConnected) {
          route = { name: "authentification" };
          redirect = { url: to.fullPath };
        }
        break;
      case CONTROLLED:
        if (!authStore.isControlled) {
          route = { name: "authentification" };
          redirect = { url: to.fullPath };
        }
        break;
      default:
        break;
    }
  } else if (to.name !== "controle") {
    route = { name: "controle" };
    redirect = { url: to.fullPath };
  }

  // Can't have a route with ?redirect=/controle

  if (redirect) {
    const redirectRoute = router.resolve(redirect.url);
    if (!redirectRoute.meta.noRedirect) {
      if (import.meta.client) {
        useSessionStorage.set("out", redirect);
      } else {
        const out = useCookie("out");
        out.value = redirect;
      }
    }
  }

  return route;
}

import validate from "/src/node_modules/nuxt/dist/pages/runtime/validate.js";
import _1_45clean_45global from "/src/middleware/1-clean.global.js";
import _2_45routing_45global from "/src/middleware/2-routing.global.js";
import manifest_45route_45rule from "/src/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _1_45clean_45global,
  _2_45routing_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}
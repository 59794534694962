/**
 * Validation rule by field (copy of php)
 * @returns {{String|null}} Code error
 */
export function useValidation() {
  return {
    username: (value) => {
      if (value.length < 2 || value.length > 30) {
        return "len_range";
      }
      if (!/.*[a-zàâäéèêëîïôöùûüÿç].*[a-zàâäéèêëîïôöùûüÿç].*/i.test(value)) {
        return "username_format";
      }
      return null;
    },
    password: (value) => {
      // if (
      //   /[\xA0\xAD\x{2000}-\x{200F}\x{2028}-\x{202F}\x{205F}-\x{206F}]/.test(
      //     value,
      //   )
      // ) {
      //   return "special_space";
      // }
      if (/\s/.test(value)) {
        return "whitespace";
      }
      if (!/(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,64}/.test(value)) {
        return "password_format";
      }
      return null;
    },
  };
}

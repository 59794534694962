export class HttpException {
  constructor(res) {
    this.status = res.status;
    this.data = res.data;
  }
}

/**
 * Parse the content of a Fetch API response thanks to the Content-Type header
 * @param {Response} response
 * @returns {Promise<any>|null}
 */
async function parseBody(response) {
  if (!response.body) {
    return null;
  }

  const type = response.headers.get("Content-Type");
  if (type) {
    if (type.match(/.*application\/json.*/g)) {
      return response.json();
    }
    if (type.match(/.*application\/pdf.*/g)) {
      return response.blob();
    }
    if (type.match(/.*image\/.*/g)) {
      return response.blob();
    }
  }
  return response.text();
}

/**
 * Handle Fetch API response
 * @param {Response} response
 * @returns {Object}
 */
export const useHandleResponse = async (response) => {
  const body = await parseBody(response);
  if (response.ok) {
    return { status: response.status, body };
  }
  throw new HttpException({
    status: response.status,
    data: body,
  });
};

/**
 * Authenticate user with access or refresh token when starting website
 */
export default defineNuxtPlugin(async (nuxtApp) => {
  const { NONE, SYNC } = useAuthStatus();
  const authStore = useAuthStore(nuxtApp.$pinia);
  const httpStore = useHttpStore(nuxtApp.$pinia);

  function login() {
    authStore.pending = SYNC;

    const dataAccess = useSessionStorage.get("access");
    const dataRefresh = useLocaleStorage.get("refresh");

    const timestamp = Date.now();
    const hasAccess =
      dataAccess?.token && (!dataAccess.exp || dataAccess.exp > timestamp);
    const hasRefresh =
      dataRefresh?.token && (!dataRefresh.exp || dataRefresh.exp > timestamp);

    // Has valid access or refresh token
    if (hasAccess || hasRefresh) {
      let url = null;
      const init = {
        method: "GET",
        headers: {},
      };

      // Renew token
      if (hasAccess) {
        url = `${import.meta.env.VITE_API_URL}api/v1/token/renew`;
        init.headers.Authorization = `Bearer ${dataAccess.token}`;
      }
      // Refresh token
      else {
        url = `${import.meta.env.VITE_API_URL}api/v1/token/refresh?token=${dataRefresh.token}`;
      }

      fetch(url, init)
        .then((res) => useHandleResponse(res))
        .then(({ body }) => {
          authStore.setToken(body);
          authStore.pending = NONE;
          httpStore.runApiQueue();

          return useAuthRedirect();
        })

        .catch(() => {
          // Delete access token
          useSessionStorage.remove("access");

          // If failed with access token retry with refresh
          if (hasAccess) {
            return login();
          }

          // Delete refresh token
          useLocaleStorage.remove("refresh");
          authStore.pending = NONE;
          httpStore.runApiQueue();
        });
    } else {
      // Delete access and refresh token
      useSessionStorage.remove("access");
      useLocaleStorage.remove("refresh");
      authStore.pending = NONE;
      httpStore.runApiQueue();
    }
  }

  login();
});

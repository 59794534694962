export const useUserStore = defineStore("user", () => {
  // States

  const id = ref(null);
  const email = ref(null);
  const name = ref(null);
  const avatar = ref(null);

  // Actions

  function setUser(data) {
    id.value = data.userId;
    email.value = data.userEmail;
    name.value = data.userName;
    avatar.value = data.userAvatar;
  }

  function resetUser() {
    email.value = null;
    email.value = null;
    name.value = null;
    avatar.value = null;
  }

  return {
    id,
    email,
    name,
    avatar,
    setUser,
    resetUser,
  };
});

if (import.meta.env.DEV && import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}

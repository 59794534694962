export const useAuthStore = defineStore("auth", () => {
  const userStore = useUserStore();
  const authStatus = useAuthStatus();

  const { NONE, ANONYMOUS, CONNECTED, CONTROLLED } = authStatus;

  // States

  const pending = ref(NONE);
  const level = ref(ANONYMOUS);

  const provider = ref(null);

  const apiToken = reactive({
    access: null,
    exp: 0,
  });

  const refreshToken = reactive({
    access: null,
    exp: 0,
  });

  // Getters

  const isPublic = computed(() => level.value >= ANONYMOUS);
  const isConnected = computed(() => level.value >= CONNECTED);
  const isControlled = computed(() => level.value === CONTROLLED);

  const accessExpUnix = computed(() => Math.floor(apiToken.exp / 1000));
  const refreshExpUnix = computed(() => Math.floor(refreshToken.exp / 1000));

  // Actions
  function setToken(data) {
    const timestamp = Date.now();

    // Auth privilege
    level.value = data.hasControl ? CONNECTED : CONTROLLED;

    // Provider use for auth (email, Google, Facebook or Apple)
    provider.value = data.provider;

    // api token
    apiToken.access = data.accessToken;
    apiToken.exp = timestamp + data.accessExpiresIn * 1000;
    useSessionStorage.set("access", {
      token: apiToken.access,
      exp: apiToken.exp,
    });

    // refresh token
    if (data.refreshToken && data.refreshExpiresIn) {
      refreshToken.access = data.refreshToken;
      refreshToken.exp = timestamp + data.refreshExpiresIn * 1000;
      useLocaleStorage.set("refresh", {
        token: refreshToken.access,
        exp: refreshToken.exp,
      });
    } else {
      refreshToken.access = null;
      refreshToken.exp = 0;
      useLocaleStorage.remove("refresh");
    }

    userStore.setUser(data);
  }

  function resetAccess() {
    apiToken.access = null;
    apiToken.exp = 0;
  }

  function resetAuth(redirect = false) {
    apiToken.access = null;
    apiToken.exp = 0;
    refreshToken.access = null;
    refreshToken.exp = 0;
    useSessionStorage.remove("access");
    useLocaleStorage.remove("refresh");
    level.value = ANONYMOUS;
    userStore.resetUser();

    // Redirect user
    if (redirect) {
      useSessionStorage.remove("out");
      const origin = useSessionStorage.get("origin");
      if (origin) {
        useSessionStorage.remove("origin");
        return navigateTo(origin.url, {
          replace: true,
          external: origin.external,
        });
      }
    }

    const route = useRoute();
    if (route.meta.auth && route.meta.auth >= CONNECTED) {
      return navigateTo({ name: "authentification" }, { replace: true });
    }
  }

  function logout(redirect = false) {
    useHttp.delete("/api/v1/logout").finally(() => {
      resetAuth(redirect);
    });
  }
  return {
    pending,
    provider,
    level,
    apiToken,
    accessExpUnix,
    refreshToken,
    refreshExpUnix,
    isPublic,
    isConnected,
    isControlled,
    setToken,
    resetAccess,
    resetAuth,
    logout,
  };
});

if (import.meta.env.DEV && import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}

export const useSnackbarStore = defineStore("snackbar", () => {
  // States
  const INFO = 1;
  const WARNING = 2;
  const ERROR = 3;
  const SUCCESS = 4;

  const keys = ref(0);
  const queue = ref([]);

  const open = ref(false);
  const key = ref(0);
  const type = ref(INFO);
  const message = ref(null);
  const action = ref(null);
  const close = ref(false);

  const resolve = ref(null);
  const reject = ref(null);

  function onAction() {
    if (resolve.value) {
      reject.value();
    }

    keys.value = useBitwise.del(keys.value, key.value);
    open.value = false;

    setTimeout(() => {
      nextToast();
    }, 500);
  }

  function onClose() {
    if (reject.value) {
      resolve.value(true);
    }

    keys.value = useBitwise.del(keys.value, key.value);
    open.value = false;

    setTimeout(() => {
      nextToast();
    }, 500);
  }

  const isInfo = computed(() => type.value === INFO);
  const isWarning = computed(() => type.value === WARNING);
  const isError = computed(() => type.value === ERROR);
  const isSuccess = computed(() => type.value === SUCCESS);

  function info(options) {
    return add(options, INFO);
  }

  function warning(options) {
    return add(options, WARNING);
  }

  function error(options) {
    return add(options, ERROR);
  }

  function success(options) {
    return add(options, SUCCESS);
  }

  function add(options, type) {
    return new Promise((resolve, reject) => {
      if (options.key && useBitwise.has(keys.value, options.key)) {
        resolve(false);
        return;
      }
      queue.value.push({
        options: { ...options, type },
        resolve,
        reject,
      });

      nextToast();
    });
  }

  function nextToast() {
    if (open.value) {
      return;
    }

    const snackbar = queue.value.shift();
    if (!snackbar) {
      return;
    }

    const {
      key: keyData,
      type: typeData,
      message: messageData,
      action: actionData,
      close: closeData,
    } = snackbar.options;

    key.value = keyData ?? 0;
    keys.value = useBitwise.add(keys.value, key.value);

    type.value = typeData;
    message.value = messageData;
    action.value = actionData ?? null;
    close.value = closeData ?? false;

    resolve.value = snackbar.resolve ?? null;
    reject.value = snackbar.reject ?? null;

    open.value = true;

    if (!action.value && !close.value) {
      setTimeout(() => {
        onClose();
      }, 4000);
    }
  }

  return {
    // Function
    onAction,
    onClose,
    // Data
    open,
    message,
    action,
    close,
    isInfo,
    isWarning,
    isError,
    isSuccess,
    // Helpers
    info,
    warning,
    error,
    success,
  };
});

if (import.meta.env.DEV && import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSnackbarStore, import.meta.hot));
}

/**
 * Default middleware handle access to route and offline page
 */
export default defineNuxtRouteMiddleware(async (to) => {
  const route = useRouteValidation(to);

  if (route) {
    return navigateTo(route);
  }
});

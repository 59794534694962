/**
 * Save cookie in session storage then delete them
 */
export default defineNuxtPlugin(() => {
  const origin = useCookie("origin");
  const out = useCookie("out");
  const access = useCookie("access");
  const refresh = useCookie("refresh");

  if (origin.value) {
    useSessionStorage.set("origin", origin.value);
    origin.value = null;
  }
  if (out.value) {
    useSessionStorage.set("out", out.value);
    out.value = null;
  }
  if (access.value) {
    useSessionStorage.set("access", access.value);
    access.value = null;
  }
  if (refresh.value) {
    useLocaleStorage.set("refresh", refresh.value);
    refresh.value = null;
  }
});

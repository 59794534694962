<template>
  <LazyHeaderDefault
    v-if="route.meta.header === 'default'"
    ref="header"
    class="h-header mx-auto"
  />
  <LazyHeaderHome
    v-else-if="route.meta.header === 'home'"
    ref="header"
    class="h-header mx-auto"
  />
  <client-only>
    <header
      v-if="!header && route.meta.header"
      class="h-header mx-auto animate-pulse bg-gray-100"
    ></header>
  </client-only>
</template>

<script setup>
const route = useRoute();

const header = useTemplateRef("header");
</script>


if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as applexq4mbXW71AK349NWYVMru_UBv250_45S5KxQLxps1ryHMMeta } from "/src/pages/authentification/callback/client/apple.vue?macro=true";
import { default as facebookXir_45SQ_45obLTnYWWmGUtiMOpi7In1ZoEWTDQEWY91u2UMeta } from "/src/pages/authentification/callback/facebook.vue?macro=true";
import { default as googlehqfCfPEqgFrHE5ZqrZMqML7YCScJskoQNY9x4xMaTSAMeta } from "/src/pages/authentification/callback/google.vue?macro=true";
import { default as indexmFipzpKkwwD0z70lkHF5Z16F_454rLhV0jM8iJAGQNb2AMeta } from "/src/pages/authentification/index.vue?macro=true";
import { default as cguSTSH50CuuF1RwTLswfwbr88UEtgDRGzoWF62ywWws7QMeta } from "/src/pages/cgu.vue?macro=true";
import { default as confidentialitenoCKjkPdnFmqugtbUN_8KnIPCF7RiEXShW8AbWAPsa4Meta } from "/src/pages/confidentialite.vue?macro=true";
import { default as controlehPMWR8PYFU6szrDS_45ZHtJs_I9LLSeDelNR_456_45pphYqcMeta } from "/src/pages/controle.vue?macro=true";
import { default as indexD8Kf5nPoGkO_45mPRcy0kXxYdHgQUU8jSfv0icJqY11_45oMeta } from "/src/pages/index.vue?macro=true";
import { default as usersLK3Nfu1hmbBbHdAHZGM9CS4WFVL9jqc0flhPmCVCgoMeta } from "/src/pages/user.vue?macro=true";
export default [
  {
    name: "authentification-callback-client-apple",
    path: "/authentification/callback/client/apple",
    meta: applexq4mbXW71AK349NWYVMru_UBv250_45S5KxQLxps1ryHMMeta || {},
    component: () => import("/src/pages/authentification/callback/client/apple.vue")
  },
  {
    name: "authentification-callback-facebook",
    path: "/authentification/callback/facebook",
    meta: facebookXir_45SQ_45obLTnYWWmGUtiMOpi7In1ZoEWTDQEWY91u2UMeta || {},
    component: () => import("/src/pages/authentification/callback/facebook.vue")
  },
  {
    name: "authentification-callback-google",
    path: "/authentification/callback/google",
    meta: googlehqfCfPEqgFrHE5ZqrZMqML7YCScJskoQNY9x4xMaTSAMeta || {},
    component: () => import("/src/pages/authentification/callback/google.vue")
  },
  {
    name: "authentification",
    path: "/authentification",
    meta: indexmFipzpKkwwD0z70lkHF5Z16F_454rLhV0jM8iJAGQNb2AMeta || {},
    component: () => import("/src/pages/authentification/index.vue")
  },
  {
    name: "cgu",
    path: "/cgu",
    meta: cguSTSH50CuuF1RwTLswfwbr88UEtgDRGzoWF62ywWws7QMeta || {},
    component: () => import("/src/pages/cgu.vue")
  },
  {
    name: "confidentialite",
    path: "/confidentialite",
    meta: confidentialitenoCKjkPdnFmqugtbUN_8KnIPCF7RiEXShW8AbWAPsa4Meta || {},
    component: () => import("/src/pages/confidentialite.vue")
  },
  {
    name: "controle",
    path: "/controle",
    meta: controlehPMWR8PYFU6szrDS_45ZHtJs_I9LLSeDelNR_456_45pphYqcMeta || {},
    component: () => import("/src/pages/controle.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexD8Kf5nPoGkO_45mPRcy0kXxYdHgQUU8jSfv0icJqY11_45oMeta || {},
    component: () => import("/src/pages/index.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: usersLK3Nfu1hmbBbHdAHZGM9CS4WFVL9jqc0flhPmCVCgoMeta || {},
    component: () => import("/src/pages/user.vue")
  }
]
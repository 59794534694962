import errorsWording from "~/assets/wording/errors.json";

const rules = useValidation();

class Errors {
  constructor(refs, form) {
    this.data = reactive({});

    this.setRefs(refs);
    this.setForm(form);
  }

  /**
   * Change wording form
   * @param {String} form
   */
  setForm(form) {
    this.form = form;
  }

  /**
   * Init Helpers with a other instance of Helpers
   * @param {Object} data export() format
   */
  import(data) {
    this.data.status = data.status;
    this.data.code = data.code;
    this.data.message = data.message;
    this.data.fields = data.fields;
  }

  /**
   * Return all the data of the instance
   */
  export() {
    return this.data;
  }

  /**
   * Return error data. A specific field can be specified
   * @param {String|null} field
   * @returns {Object} Error data
   */
  get(field = null) {
    if (field) {
      return this.data.fields?.[field] ?? {};
    }
    return this.data;
  }

  /**
   * Return error message. A specific field can be specified
   * @param {String|null} field
   * @returns {String}
   */
  getMessage(field = null) {
    return this.get(field).message;
  }

  /**
   * Return true if there is a error. A specific field can be specified
   * @param {String|null} field
   * @returns {Boolean}
   */
  has(field = null) {
    return !!this.get(field).message;
  }

  /**
   * Remove all error. A specific field can be specified
   * @param {String|null} field
   */
  reset(field = null) {
    if (field) {
      if (this.data.fields?.[field]) {
        delete this.data.fields[field];

        delete this.#getRef(field)?.dataset.state;
        if (Object.keys(this.data.fields).length) {
          return;
        }
      } else {
        return;
      }
    }
    delete this.data.status;
    delete this.data.code;
    delete this.data.message;
    delete this.data.fields;
    delete this.data.extra;

    this.refs.forEach((ref) => {
      const element = this.#getRef(ref);
      if (element) {
        delete element.dataset.state;
      }
    });

    return true;
  }

  /**
   * Add a error
   * @param {HttpException|String|Integer} field
   * @param {String|null} code
   * @param {Object|null} extra
   */
  set(field, code = null, extra = null) {
    // Create a error with a HttpException send by the Fetch wrapper
    if (field instanceof HttpException) {
      this.reset();

      const { status, error, errors, extra } = field.data;
      this.#setGlobal(status, error, extra);
      if (errors) {
        Object.keys(errors).forEach((key) => {
          this.#addField(this.form, key, errors[key], extra);
        });
      }
    }
    // Create a global error
    else if (Number.isInteger(field)) {
      this.reset();
      this.#setGlobal(field, code, extra);
    }
    // Create a field error
    else {
      if (this.get().status !== 400 || this.get().code !== "validation") {
        this.#setGlobal(400, "validation", null);
      }
      this.#addField(this.form, field, code, extra);
    }
  }

  getWording(form, field, code, extra) {
    let message = null;

    if (errorsWording[form] && errorsWording[form][field]) {
      message =
        errorsWording[form][field][code] ?? errorsWording[form][field].default;
    }

    if (!message && errorsWording.default && errorsWording.default[field]) {
      message =
        errorsWording.default[field][code] ??
        errorsWording.default[field].default;
    }

    if (message) {
      if (extra) {
        Object.keys(extra).forEach((key) => {
          message = message.replaceAll(`{{${key}}}`, extra[key]);
        });
      }
      return message;
    }

    return errorsWording._unknown ?? "Une erreur est survenue";
  }

  // -------------------------------------------------------
  // Ref necessary
  // -------------------------------------------------------

  /**
   * Change the list of input link to the helper
   * @param {String} refs List of fields name
   */
  setRefs(refs) {
    this.refs = refs;
    this.refsCache = {};

    refs.forEach((ref) => {
      this.refsCache[ref] = useTemplateRef(ref);
    });
  }

  /**
   * Focus the first field in error
   */
  focus() {
    this.refs.every((field) => {
      if (this.get(field).message) {
        this.#getRef(field)?.focus();
        return false;
      }
      return true;
    });
  }

  /**
   * Reset the error of the field if he is valid. Use Validation rule and Http validity
   * @param {String} ref Name of the field
   */
  input(ref) {
    const field = this.#getRef(ref);
    if (!field) {
      return;
    }
    const error = rules[field.dataset.pattern]
      ? rules[field.dataset.pattern](field.value)
      : null;

    if (
      !field.value ||
      this.get(ref).code === "required" ||
      (field.validity.valid && !error)
    ) {
      this.reset(ref);
    }
  }

  /**
   * Add a error to the field if he is invalid. Use Validation rule and Http validity
   * @param {String} ref Name of the field
   */
  blur(ref) {
    const field = this.#getRef(ref);
    if (!field) {
      return;
    }
    const error = rules[field.dataset.pattern]
      ? rules[field.dataset.pattern](field.value)
      : null;

    if (field.value && (!field.validity.valid || error)) {
      this.#setGlobal(400, "validation", null);
      this.#addField(this.form, ref, error ?? "default", null);
    }
  }

  /**
   * Add errors to all invalid fields
   * @param {array<String>} refs List of input to check (all input link by default)
   */
  submit(refs = null) {
    const listRefs = refs ?? Object.keys(this.refsCache);

    listRefs.forEach((ref) => {
      const field = this.#getRef(ref);
      if (field) {
        let error = rules[field.dataset.pattern]
          ? rules[field.dataset.pattern](field.value)
          : null;
        if (!field.validity.valid || error) {
          this.#setGlobal(400, "validation", null);
          if (field.validity.valueMissing) {
            error = "required";
          }
          this.#addField(this.form, ref, error ?? "default", null);
        }
      }
    });
    this.focus();
  }

  // -------------------------------------------------------
  // Private
  // -------------------------------------------------------

  #getRef(field) {
    if (field) {
      const ref = unref(this.refsCache[field]);
      if (ref) {
        return ref.customElement ? unref(ref.customElement()) : ref;
      }
    }
    return null;
  }

  #setGlobal(status, code, extra) {
    this.data.status = status;
    this.data.code = code;
    this.data.message = this.getWording("http", status, code, extra);
    this.data.extra = extra;
  }

  #addField(form, field, code, extra) {
    this.data.fields = {
      ...(this.data.fields ?? {}),
      [field]: {
        code,
        message: this.getWording(form, field, code, extra),
        extra,
      },
    };

    const element = this.#getRef(field);
    if (element) {
      delete element.dataset.state;
      element.dataset.state = "invalid";
    }
  }
}

/**
 * Helpers use to handle errors
 * @param {*} refs List if fields name to handle
 * @param {*} form Wording form name
 */
export const useFormError = (refs = [], form = "default") => {
  return new Errors(refs, form);
};

export const useHttpStore = defineStore("http", () => {
  // States

  const apiQueue = ref([]);

  // Actions

  function addApiQueue(data) {
    apiQueue.value.push(data);
  }

  function runApiQueue() {
    apiQueue.value.forEach((req) => {
      req
        .promise()
        .then((response) => {
          req.resolve(response);
        })
        .catch((error) => {
          req.reject(error);
        });
    });
    apiQueue.value = [];
  }

  return {
    apiQueue,
    addApiQueue,
    runApiQueue,
  };
});

if (import.meta.env.DEV && import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useHttpStore, import.meta.hot));
}

/**
 * Server only middleware
 * Capture query params in url and store them in cookies, then remove query params from url by doing a redirect
 * (A redirect on server side is a hard reload, you will lose all data prior to the redirect (store, state, ...).
 * To avoid that you can store the data in a cookie)
 */
export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.client) return;

  const { name, params, query } = to;

  const origin = useCookie("origin");
  const out = useCookie("out");
  const access = useCookie("access");
  const refresh = useCookie("refresh");

  if (query.origin || query.out || query.access || query.refresh) {
    origin.value = query.origin ? { url: query.origin, external: true } : null;
    out.value = query.out ? { url: query.out, external: true } : null;
    access.value = query?.access;
    refresh.value = query?.refresh;

    delete query.origin;
    delete query.out;
    delete query.access;
    delete query.refresh;

    return navigateTo({ name, params, query });
  }
});

<template>
  <LazyFooterDefault
    v-if="route.meta.footer === 'default'"
    ref="footer"
    class="h-footer mx-auto"
  />
  <LazyFooterControl
    v-else-if="route.meta.footer === 'control'"
    ref="footer"
    class="h-footer mx-auto"
  />
  <client-only v-if="!footer && route.meta.footer">
    <footer class="h-footer mx-auto animate-pulse bg-gray-100"></footer>
  </client-only>
</template>

<script setup>
const route = useRoute();

const footer = useTemplateRef("footer");
</script>

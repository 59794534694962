let storePrefixe = "AGENDA_ORG";

switch (import.meta.env.VITE_MODE) {
  case "developpement":
    storePrefixe = `LOCAL_${storePrefixe}`;
    break;
  case "testing":
    storePrefixe = `DEV_${storePrefixe}`;
    break;
  default:
    break;
}

let hasSessionStorage = null;
const fakeStorage = {};

/**
 * Check is session storage is supported
 */
function isSupported() {
  if (import.meta.server || hasSessionStorage !== null)
    return hasSessionStorage;
  try {
    const key = `SUPPORT_${storePrefixe}`;
    sessionStorage.setItem(key, key);
    sessionStorage.removeItem(key);
    hasSessionStorage = true;
  } catch {
    hasSessionStorage = false;
    useBugsnag().notify("SessionStorage not supported");
  }
  return hasSessionStorage;
}

function get(field) {
  if (isSupported()) {
    const item = sessionStorage.getItem(`${storePrefixe}-${field}`) ?? null;
    if (item) {
      return JSON.parse(item);
    }
  }
  return fakeStorage[field] ?? null;
}

function set(field, data) {
  if (isSupported()) {
    sessionStorage.setItem(`${storePrefixe}-${field}`, JSON.stringify(data));
  } else {
    fakeStorage[field] = data;
  }
}

function remove(field) {
  if (isSupported()) {
    sessionStorage.removeItem(`${storePrefixe}-${field}`);
  } else {
    delete fakeStorage[field];
  }
}

/**
 * Session storage helper, fallback to a object if not supported
 */
export const useSessionStorage = {
  get,
  set,
  remove,
};

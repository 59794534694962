export function useAuthStatus() {
  return {
    // Access
    ANONYMOUS: 0,
    CONNECTED: 1,
    CONTROLLED: 2,

    // Token status
    ERROR: 0,
    PENDING: 1,
    DONE: 2,

    // Pending type
    NONE: 0,
    ASYNC: 1,
    SYNC: 2,
  };
}

export const useClientStore = defineStore("client", () => {
  // States

  const isIOS = ref(false);
  const isStandalone = ref(false);

  const height = ref(0);
  const width = ref(0);

  return {
    isIOS,
    isStandalone,
    height,
    width,
  };
});

if (import.meta.env.DEV && import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useClientStore, import.meta.hot));
}

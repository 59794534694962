export const useAuthRedirect = () => {
  const authStore = useAuthStore();

  if (authStore.isConnected && !authStore.isControlled) {
    return navigateTo({ name: "controle" }, { replace: true });
  }

  // There is a redirection url -----------------------------------------------------------------
  const out = useSessionStorage.get("out");
  if (out) {
    useSessionStorage.remove("out");

    // External redirect ------------------------------------------------------------------------
    if (out.external) {
      useSessionStorage.remove("origin");
      return navigateTo(
        {
          path: out.url,
          query: {
            access_token: authStore.apiToken.access,
            access_expire: authStore.accessExpUnix,
            refresh_token: authStore.refreshToken.access,
            refresh_expire: authStore.refreshExpUnix,
          },
        },
        {
          replace: true,
          external: true,
        },
      );
    }

    // Internal redirect ------------------------------------------------------------------------
    return navigateTo(out.url, { replace: true });
  }

  const from = useRoute();
  const redirectRoute = useRouteValidation(from);
  // Default redirection  -----------------------------------------------------------------------
  if (redirectRoute) {
    return navigateTo(redirectRoute, { replace: true });
  }

  // Redirection not necessary --------------------------------------------------------
  return false;
};

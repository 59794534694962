/**
 * Get a new access token with a access or refresh token
 * @returns {integer} [0,2] => [ERROR,DONE]
 */
function fetchToken() {
  const httpStore = useHttpStore();
  const authStore = useAuthStore();
  const { NONE, SYNC, ASYNC, ERROR, DONE } = useAuthStatus();

  const renew = authStore.pending === ASYNC;

  let url = null;
  const init = {
    method: "GET",
    headers: {},
  };
  if (renew) {
    url = `${import.meta.env.VITE_API_URL}api/v1/token/renew`;
    init.headers.Authorization = `Bearer ${authStore.apiToken.access}`;
  } else {
    url = `${import.meta.env.VITE_API_URL}api/v1/token/refresh?token=${authStore.refreshToken.access}`;
  }

  return fetch(url, init)
    .then((res) => useHandleResponse(res))
    .then(async ({ body }) => {
      // Nothing happen if the user control is created after the login/refresh
      // If the user has user control on login/refresh but don't after renew the user auth level is updated
      if (renew && body.hasControl && authStore.isControlled) {
        body.hasControl = false;
      }
      authStore.setToken(body);
      authStore.pending = NONE;
      httpStore.runApiQueue();

      return DONE;
    })
    .catch(async () => {
      // If access failed retry with valid refresh
      if (
        renew &&
        authStore.refreshToken.access &&
        authStore.refreshToken.exp > Date.now()
      ) {
        authStore.pending = SYNC;
        return fetchToken();
      }

      // Disconnect user
      await authStore.resetAuth();
      authStore.pending = NONE;
      httpStore.runApiQueue();
      return ERROR;
    });
}

/**
 * Check validity of access token
 * @returns {integer} [0,1,2] => [ERROR,PENDING,DONE]
 */
export const useTokenStatus = async () => {
  // Renew async every 15 min (access 1 hour)
  const ASYNC_REFRESH_DELAY = 1000 * 60 * 45;
  const { NONE, SYNC, ASYNC, ERROR, DONE, PENDING } = useAuthStatus();

  const httpStore = useHttpStore();
  const authStore = useAuthStore();

  // authentification/refresh pending
  if (authStore.pending === SYNC) {
    return PENDING;
  }

  const timestamp = Date.now();

  // not connected or access expire in more than 45 min
  if (
    !authStore.isConnected ||
    authStore.apiToken.exp > timestamp + ASYNC_REFRESH_DELAY
  ) {
    return DONE;
  }

  // expire in less than 45 min => renew async (every 15 min)
  if (authStore.apiToken.exp > timestamp) {
    if (authStore.pending === NONE) {
      authStore.pending = ASYNC;
      fetchToken();
    }
    return DONE;
  }

  // access expired => valid refresh sync
  if (authStore.refreshToken.access && authStore.refreshToken.exp > timestamp) {
    const currentPending = unref(authStore.pending);
    authStore.pending = SYNC;
    return currentPending === NONE ? fetchToken() : PENDING;
  }

  // Disconnect user
  await authStore.resetAuth();
  httpStore.runApiQueue();
  return ERROR;
};

<template>
  <a href="#content" class="sr-only focus:not-sr-only"> Aller au contenu </a>
  <Header />
  <main id="content" class="max-w-content min-h-main mx-auto mb-24">
    <NuxtPage />
  </main>

  <client-only>
    <!-- NO USE FOR NOW -->
    <!-- <GDialog
      id="error"
      ref="dialog"
      class="dialog-center laptop:[--dialog-width:40vw] [--dialog-height:20vh] [--dialog-width:90vw]"
    >
      <header class="p-xs flex justify-end bg-blue-50">
        <form method="dialog">
          <GButton type="submit">
            <template #icon>
              <Icon name="fa6-solid:x" />
            </template>
          </GButton>
        </form>
      </header>

      <section class="p-sm text-error flex items-center justify-center gap-2">
        <Icon name="fa6-solid:circle-xmark" />
        {{ error.getWording("http", dialogData?.status, dialogData?.error) }}
      </section>
    </GDialog> -->

    <GSnackbar />
  </client-only>

  <Footer />
</template>

<script setup>
useHead({
  htmlAttrs: {
    lang: "fr",
  },
});
useSeoMeta({
  title: "Agenda.org - Le réseau des agendas",
  description:
    "Partenaire de vos sorties en France, Suisse et Belgique. Trouvez les événements autour de vous sur l'un de nos 10 agendas thématiques.",
});

usePreloadFonts();

// const error = useFormError();

// const dialog = useTemplateRef("dialog");
// const dialogData = useState("dialog");
</script>

<style scoped>
body {
  &:has(> header):has(> footer) main {
    --min-height-main: calc(
      100vh - var(--height-header) - var(--height-footer) - var(--mb-main)
    );
  }

  &:has(> header):not(:has(> footer)) main {
    --min-height-main: calc(100vh - var(--height-header) - var(--mb-main));
  }

  &:has(> footer):not(:has(> header)) main {
    --min-height-main: calc(100vh - var(--height-footer) - var(--mb-main));
  }
}
</style>

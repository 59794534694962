<template>
  <a href="#content" class="sr-only focus:not-sr-only"> Aller au contenu </a>

  <main id="content" class="max-w-content mx-auto mb-24">
    <div class="p-md flex justify-center">
      <div class="w-form gap-xxl flex flex-initial flex-col">
        <!-- Handle 404 -->
        <template v-if="props.error.statusCode === 404">
          <h1 class="mt-xl text-center text-blue-500">Page introuvable</h1>

          <div class="flex items-center justify-center gap-2">
            <Icon
              name="fa6-solid:magnifying-glass"
              class="flex-none text-5xl"
            />
            <p class="text-h2">La page demandée n'a pas été trouvée !</p>
          </div>
        </template>

        <!-- Handle 500 -->
        <template v-else>
          <h1 class="mt-xl text-center text-blue-500">
            Une erreur est survenue
          </h1>

          <div class="text-error flex items-center justify-center gap-2">
            <Icon name="fa6-solid:circle-xmark" class="flex-none text-5xl" />
            <p class="text-h2 text-center">
              {{ errors.getWording("http", 500, "default") }}
            </p>
          </div>

          <p class="text-h2 text-center">
            Si le problème persiste
            <GLink
              to="https://info.agenda.org/"
              rel="noopener noreferrer"
              target="_blank"
              class="semibold text-h2"
            >
              contactez-nous </GLink
            >.
          </p>
        </template>

        <GButton :disabled="loading" @click="clear()"
          >Aller à l'accueil
          <template v-if="loading" #append> <GSpinner /> </template
        ></GButton>
      </div>
    </div>
  </main>
</template>

<script setup>
const props = defineProps({
  error: {
    type: Object,
    required: true,
  },
});

const errors = useFormError();
const loading = ref(false);

function clear() {
  loading.value = true;
  setTimeout(() => {
    loading.value = false;
  }, 1000);
  clearError({ redirect: "/" });
}

useSeoMeta({
  description: "",
});
</script>

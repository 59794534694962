export default defineNuxtPlugin((nuxtApp) => {
  const clientStore = useClientStore(nuxtApp.$pinia);

  clientStore.isIOS = /iphone|ipad|ipod/i.test(navigator.userAgent);
  clientStore.isStandalone = !!(
    window.matchMedia("(display-mode: standalone)").matches ||
    window.navigator.standalone
  );

  let throttle = false;
  function throttleHandler() {
    if (throttle) {
      return;
    }

    clientStore.width = window.innerWidth;
    clientStore.height = window.innerHeight;

    throttle = true;
    setTimeout(() => {
      throttle = false;
      if (
        clientStore.width !== window.innerWidth ||
        clientStore.height !== window.innerHeight
      ) {
        throttleHandler();
      }
    }, 100);
  }

  clientStore.width = window.innerWidth;
  clientStore.height = window.innerHeight;
  window.addEventListener("resize", () => throttleHandler());
});
